/* eslint-disable */
let subIP = false;
let initializeForm = function(form, requiredFields) {
	form.addEventListener('submit', function(e) {
		e.stopImmediatePropagation();
		e.preventDefault();
		let responseMessage = form.querySelector('.response_message');
		if (form.querySelector('#hp').value !== '') return false;

		Array.prototype.forEach.call(form.querySelectorAll('.error'), function(error) {
			error.classList.remove('error');
		});
		form.querySelector('.response_message').innerHTML = '';

		if (subIP) {
			return;
		}

		let errorsFound = new Array();
		requiredFields.forEach(function(item) {
			if (item == 'message') {
				const regex = /(http:|https:|www.)/i;
				if (regex.test(form.querySelector('#' + item + '_input').value)) {
					errorsFound.push(item);
				}
			}
			if (form.querySelector('#' + item + '_input').value == '') {
				form.querySelector('#' + item + '_input').classList.add('error');
				errorsFound.push(item);
			}
		});

		if (!subIP && errorsFound.length == 0) {
			subIP = true;
			let request = new XMLHttpRequest(),
				method = 'POST',
				url = form.getAttribute('action'),
				data = new FormData(form);

			request.onreadystatechange = function() {
				if (request.readyState === 4 && request.status === 200) {
					let response = JSON.parse(request.responseText);
					if (response.ret_det.success) {
						let input = form.querySelector('#contact_form_submit');
						input.parentElement.removeChild(input);
						responseMessage.innerHTML = "<span class='success'>" + response.ret_det.message + "</span>";
					} else {
						if (typeof response.data.error_fields !== 'undefined') {
							let errors = [];
							if (response.data.error_fields.length == 1) {
								for (let x = 0; x < response.data.error_fields.length; x++) {
									if (response.data.error_fields[x].field_name !== 'recaptcha_token') {
										errors.push(response.data.error_fields[x].field_name);
									}
								}
								responseMessage.innerHTML = "<span class='error'>" + updateResponseMessage(errors); +"</span>";
							}
						} else {
							responseMessage.innerHTML = "<span class='error'>" + response.ret_det.message + "</span>";
						}
					}
					subIP = false;
				}
			};
			request.open(method, url, true);
			request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
			request.send(data);
		}
		else if (errorsFound.length > 0) {
			responseMessage.innerHTML = "<span class='error'>" + updateResponseMessage(errorsFound); +"</span>";
		}
		else {
			responseMessage.innerHTML = "<span class='error'>There was a problem processing your request.</span>";
		}
	});
};

function updateResponseMessage(errors) {
	let response = '';
	let messagenotice = '';
	if (errors.includes('message')) {
		messagenotice = ' URLs are not allowed in the message.';
	}
	if (errors.length == 1) {
		response = "The " + getFriendlyFieldName(errors[0]) + " field is required." + messagenotice;
	} else if (errors.length == 2) {
		response = "The " + getFriendlyFieldName(errors[0]) + " and " + getFriendlyFieldName(errors[1]) + " fields are required." + messagenotice;
	} else {
		let fields = "";
		for (let x = 0; x < errors.length; x++) {
			fields += getFriendlyFieldName(errors[x]);
			if (x < errors.length - 1) {
				fields += ', ';
			}
			if (x == errors.length - 2) {
				fields += 'and ';
			}
		}
		response = "The " + fields + " fields are required." + messagenotice;
	}

	return response;
}

const fieldFriendly = {
	name: "name",
	email: "email",
	subject: "subject",
	message: "message",
}

const getFriendlyFieldName = function(field) {
	if (typeof fieldFriendly[field] !== "undefined") {
		return fieldFriendly[field];
	} else {
		return field;
	}
}
export const contactGlobal = (function() {
	let my = {};

	function _globalContact() {
		if (document.querySelector('#contact_global_form')) {
			initializeForm(document.getElementById('contact_global_form'), ['name', 'email', 'subject', 'message']);
		}
	};

	my.init = function() {
		_globalContact();
	};

	return my;
})();
