export const ctaCaro = (function() {
	let my = {};

	function _startCaro() {
		if (document.querySelector('#cta_caro') && document.querySelector('.carousel_item.full') === null) {
			let carousel = document.getElementById('cta_caro');
			let dotsContainer = document.getElementById('dots_container');
			let dots = [];
			let currentSlide = 0;

			/* eslint-disable */
			for (let i = 0; i < totalSlides; i++) {
				let dot = document.createElement('span');
				dot.classList.add('dots');
				dotsContainer.appendChild(dot);
				dots.push(dot);
			}

			dots[0].classList.add('active');

			function updateCarousel() {
				dots.forEach(function(dot) {
					dot.classList.remove('active');
				});
				dots[currentSlide].classList.add('active');
				let translateValue = -currentSlide * 100 + '%';
				carousel.style.transform = 'translateX(' + translateValue + ')';
			}

			dots.forEach(function(dot, index) {
				dot.addEventListener('click', function() {
					currentSlide = index;
					updateCarousel();
				});
			});

			const leftChevrons = document.querySelectorAll('#caro_left_chevron');

			leftChevrons.forEach((leftChevron) => {
				leftChevron.addEventListener('click', function() {
					currentSlide = (currentSlide + 1) % totalSlides;
					updateCarousel();
				});
			})

			// Auto Play Carousel
			// setInterval(nextSlide, 5000);
		}
	}

	my.init = function() {
		_startCaro();
	};

	return my;
})();
