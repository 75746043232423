export const navToggle = (function(){
	let my = {};

	function _addDoubleClick() {
		[].forEach.call(document.querySelectorAll('.d_click'), (el) => {
			el.addEventListener('click', e => {
				if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
					if (!el.hasAttribute('clicked')) {
						e.preventDefault();
						el.setAttribute('clicked', true);
					}
				}
			});
		});
	}

	function _mobileToggle() {
		document.getElementById('site_nav_toggle').addEventListener('click', function(e){
			let btn = e.currentTarget;
			let nav = document.getElementById('site_header_nav');

			if(btn.classList.contains('open')) {
				btn.classList.remove('open');
				nav.classList.remove('open');
			} else {
				btn.classList.add('open');
				nav.classList.add('open');
			}
		});
	}

	function _menuClick() {
		const menuButtons = document.querySelectorAll('.nav_item');
		const dropDowns = document.querySelectorAll('.dropdown');
		menuButtons.forEach((menu) => {
			if(menu.classList.contains('mobile')) return;
			const hasNav = menu.querySelector('.has_drop_header');
			if(hasNav !== null) {
				const dropDown = menu.querySelector('.dropdown');
				hasNav.addEventListener('click', () => {
					let shouldOpen = !dropDown.classList.contains('menu_active');
					dropDowns.forEach((item) => {
						item.classList.remove('menu_active');						
					});
					if (shouldOpen) {
						dropDown.classList.add('menu_active');
					}
				});
			}
		});
	}

	my.init = function(){
		_addDoubleClick();
		_mobileToggle();
		_menuClick();
	};

	return my;
})();


// 	.has_drop {
// 		color: $primary;
// 	}

// 	.dropdown {
// 		transform: translateX(-50%) scale(1);
// 		pointer-events: auto;

// 		opacity: 1;